import React from 'react';
import './App.css';
import Landing from "./pages/Landing";

function App() {
    return (
        <div className="App">
            <Landing/>
        </div>
    );
}

export default App;
